const SITE_NAME = process.env.SITE_NAME || '@mazipan';
const FULL_DOMAIN = process.env.FULL_DOMAIN || 'https://mazipan.space';

export interface WebsiteConfig {
  title: string;
  description: string;
  logo: string;
  lang: string;
  siteUrl: string;
  facebook?: string;
  twitter?: string;
  github?: string;
  linkedin?: string;
  footer?: string;
}

const config: WebsiteConfig = {
  title: SITE_NAME,
  description: "Power is gained by sharing knowledge.",
  logo: 'images/peace.png',
  lang: 'eng',
  siteUrl: FULL_DOMAIN,
  facebook: 'https://www.facebook.com/Hari446',
  twitter: 'https://twitter.com/get_hariharan',
  github: 'https://github.com/gethari',
  linkedin: 'https://www.linkedin.com/in/hari-haransubramanian/',
  footer: "The opinions expressed herein are my own personal opinions and do not represent my employer's view in any way.",
};

export default config;
